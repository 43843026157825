@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  justify-content: center;
}

a, button {
  all: unset;
}
